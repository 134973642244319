<template>
  <div class="Finished">
    <div class="main">
      <component
        v-if="templateType == 'birthday'"
        :is="Birthday"
        :list="list"
        ref="letter"
      ></component>
      <component
        v-if="templateType == 'commemoration'"
        :is="Commemoration"
        :list="list"
        ref="letter"
      ></component>
      <component
        v-if="templateType == 'loveLetter'"
        :is="LoveLetter"
        :list="list"
        ref="letter"
      ></component>
      <component
        v-if="templateType == 'newYear'"
        :is="NewYear"
        :list="list"
        ref="letter"
      ></component>
      <component
        v-if="templateType == 'valentine'"
        :is="Valentine"
        :list="list"
        ref="letter"
      ></component>
    </div>
    <div class="bottom" v-if="this.WX">
      <div class="btn" @click="gotoEdit">
        <img src="@/assets/icon/remake.png" alt="" />
        重新制作
      </div>
    </div>
    <van-dialog
      v-model="dialogVisible"
      title="提示"
      show-cancel-button
      closeOnClickOverlay
      message="是否保留现有情书内容"
      @confirm="onConfirm"
      @cancel="onCancel"
    ></van-dialog>
    <audio :src="playingMusic" ref="audio"></audio>
    <el-tooltip
      effect="dark"
      content="点击播放音乐"
      placement="bottom-start"
      v-model="tip"
      manual
    >
      <div class="icon play" v-if="play" @click="togglePlay"></div>
      <div class="icon pause" v-else @click="togglePlay"></div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      Birthday: () => import("@/components/birthday"),
      Commemoration: () => import("@/components/commemoration"),
      LoveLetter: () => import("@/components/loveLetter"),
      NewYear: () => import("@/components/newYear"),
      Valentine: () => import("@/components/valentine"),
      WX: false,
      list: [],
      templateType: "",
      music: "",
      playingMusic: "",
      play: false,
      dialogVisible: false,
      tip: false,
      interval: Object,
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.play = false
        this.$refs.audio.pause();
      } else {
        setTimeout(() => {
          this.togglePlay();
        }, 1000);
      }
    });
    this.WX = +this.$route.params.WX;
    this.$cloud
      .callFunction({
        name: "router",
        data: {
          url: "getInfo",
          params: {
            UUID: this.$route.params.UUID,
          },
        },
      })
      .then((res) => {
        const { list, music, type } = res.result.data;
        this.list = list;
        this.templateType = type;
        this.music = music;
        let indexList = [];
        let cloudList = this.list
          .map((item, index) => {
            if (item.path && item.path.startsWith("cloud")) {
              indexList.push(index);
              return item.path;
            } else {
              return undefined;
            }
          })
          .filter((item) => item);
        // 下载图片
        this.$cloud
          .getTempFileURL({
            fileList: cloudList,
          })
          .then((res) => {
            indexList.forEach((item, index) => {
              this.$set(
                this.list,
                item,
                Object.assign(this.list[item], {
                  path: res.fileList[index].tempFileURL,
                })
              );
            });
          });
        // 下载音乐
        this.$cloud
          .getTempFileURL({
            fileList: [this.music],
          })
          .then((res) => {
            this.tip = true;
            console.log(res.fileList[0].tempFileURL);
            this.playingMusic = res.fileList[0].tempFileURL;
          });
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      this.tip = false;
    }, 3000);
    setTimeout(() => {
      this.$refs.letter.autoScroll();
    }, 2000);
  },
  methods: {
    gotoEdit() {
      this.dialogVisible = true;
    },
    onConfirm() {
      this.$router.push({
        path: `/edit/${this.templateType}/0/${this.$route.params.UUID}`,
      });
    },
    onCancel() {
      this.$router.push({
        // path: `/edit/${this.templateType}/1/${this.$route.params.UUID}`,
        path: `/home/${this.$route.params.UUID}`,
      });
    },
    togglePlay() {
      this.play = !this.play;
      if (this.play) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
  },
};
</script>

<style lang="scss">
.el-tooltip__popper.is-dark {
  background: rgba($color: #000000, $alpha: 0.8);
}
.el-tooltip__popper[x-placement^="bottom"] .popper__arrow::after {
  border-bottom-color: rgba($color: #000000, $alpha: 0.8);
}
.el-tooltip__popper[x-placement^="bottom"] .popper__arrow {
  border-bottom-color: transparent;
}
</style>

<style lang="scss" scoped>
.Finished {
  width: 100vw;
  height: 100vh;
  .main {
    width: 100%;
    // height: calc(100vh - 87px);
    height: 100%;
  }
  .bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 87px;
    background: #fff;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .btn {
      width: 40%;
      height: 46%;
      border-radius: 500px;
      border: 1px solid #eb5c69;
      display: flex;
      align-items: center;
      color: #eb5c69;
      justify-content: space-evenly;
      padding: 0 10px;
      margin-bottom: 20px;

      img {
        height: 70%;
      }
    }
  }

  .icon {
    width: 40px;
    height: 40px;
    position: fixed;
    top: 8px;
    right: 8px;
    z-index: 99;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid transparent;

    &.play {
      filter: none;
      animation: round 4s linear infinite;
      background-image: url("~@/assets/icon/playMusic.png");
    }

    &.pause {
      background-image: url("~@/assets/icon/pauseMusic.png");
    }
  }

  @keyframes round {
    0% {
      transform: rotateZ(0);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
}
</style>